<template>
  <div class="form-popup-bg" :class="isFormVisible ? 'is-visible':''">
    <div class="form-container">
      <button @click="toggleForm()" id="btnCloseForm" class="close-button">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
      </button>
      <div class="t-center"><div class="modal-title m-b-md">Try Demo</div></div>

      <div class="row">
        <div class="col t-center">
          <div class="demo-account">Proofng Side</div>
          <p>Username: demo@proofingmonkey.com<br/>
          Password: demo</p>
          <button class="form-bt" @click="openAdmin()">Open Proofng Side</button>
        </div>
        <div class="col t-center">
          <div class="demo-account">Client Side</div>
          <p>Username: client@proofingmonkey.com<br/>
          Password: client</p>
          <button class="form-bt" @click="openClient()">Open Client Side</button>
        </div>
      </div>

      <div class="m-t-xl t-center">
        Proofing Monkey is accessible on mobile devices, although it is currently optimized for desktop use. Our team is actively working on enhancing our platform's capabilities. We encourage you to stay tuned for future updates regarding mobile accessibility.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TryDemo",
  data() {
    return {
      //isFormVisible: false,
    };
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible;
    },
    openClient() {
      window.open("https://demo.proofingmonkey.com/", "_blank");
    },
    openAdmin() {
      window.open("https://demo.proofingmonkey.com/admin", "_blank");
    },
  },
  computed: {
    isFormVisible: {
      get: function () {
        return this.$store.state.modals.trydemo;
      },
      // setter
      set: function () {
        this.$store.commit("toggleModal", "trydemo");
        return false;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.demo-account{
  font-size: 2rem;
  font-weight: 900;
}
</style>