import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueAxios, axios);

axios.defaults.baseURL = "https://proofingmonkey.com/api";

Vue.prototype.$env = {
  siteTitle: "Proofing Monkey",
  siteURL: "https://proofingmonkey.com/",
  api: "https://proofingmonkey.com/api",
  threeD: "https://proofingmonkey.com/assets",
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
